import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import './CreateResponses.scss'
import { getConfig, saveIc, getIcfs, getPrompt } from './lib/utils'
import * as st8 from './state'
import { ethers } from 'ethers'
import { append, path, uniq } from 'ramda'
import { useOutletContext } from 'react-router-dom'
import Fuse from 'fuse.js'
import Samesies from './Samesies'

function CreateResponses ({ onSave }) {
  const [responses, setResponses] = useOutletContext()
  const [response, setResponse] = useState('')
  const [sames, setSames] = useState(false)
  const perspectives = useRecoilValue(st8.perspectivesState)
  const icFileServerUser = useRecoilValue(st8.icFileServerUserState)
  const icfsConfig = useRecoilValue(st8.icfsState)
  const maxChars = getConfig('maxChars')
  const minResponses = getConfig('minResponses')
  const maxResponses = getConfig('maxResponses')
  const responseMultiplier = 5

  // placeholder
  let placeholder = `describe "${getPrompt()}"?`
  if (responses.length >= minResponses) {
    placeholder = `cool. now go to round 2, or max your points with ${maxResponses} reponses!`
  } else if (responses.length > 5) {
    placeholder = `you got it. ${minResponses - responses.length} more to play...`
  } else if (responses.length > 0) {
    placeholder = `great. how about another?\n${minResponses - responses.length} more to go...`
  }

  // const submitted = saved.find(s => s.address === icFileServerUser)

  const _addResponse = (r) => {
    if (!r) {
      r = response.trim()
    }
    setResponses(uniq(responses.concat(r)).slice(-maxResponses))
    setResponse('')
  }

  const addResponse = () => {
    if (response === '') return
    const r = response.trim()
    const fuse = new Fuse(icfsConfig.disambigs, { keys: ['tag', 'options'], includeScore: true })
    const res = fuse.search(r)
    const score = path([0, 'score'], res)
    if(score < 0.2) {
      setSames({
        original: r,
        suggested: path([0, 'item', 'tag'], res)
      })
    } else {
      _addResponse()
    }
  }
  let show = <>
    <div className='form-group'>
      <textarea 
        autoFocus
        value={response}
        onChange={(e) => setResponse(e.target.value.toLowerCase().replace(/[^a-z0-9'" ]/g, '').slice(0, maxChars))}
        onKeyUp={(e) => {
          if (e.keyCode === 13) {
            addResponse()
          }
        }}
        onBlur={addResponse}
        className='form-control' 
        placeholder={placeholder}
      />
      <div className='char-count'>{response.length} of {maxChars}</div>
    </div>
    {responses.length < 1 && response && <div className='enter'>done? press "enter"</div>}
    <ul className='responses'>
      {responses.map((response, index) => ( <li key={index}>+ {response} <a onClick={() => {
        setResponses(responses.filter((_, i) => i !== index))
      }}>🗑</a></li> ))}
    </ul>
    <div className={classNames('stats', { error: responses.length < minResponses })}>
      { responses.length > 0 && <div className='how-many'>
        {responses.length} / {responses.length < minResponses ? minResponses : maxResponses}
      </div>}
      {responses.length >= minResponses && 
        <button className='btn' onClick={() => onSave && onSave()}>
          Submit and go to Round 2</button>
      }
    </div>
  </>
  if (sames) {
    show = <Samesies original={sames.original} suggested={sames.suggested} onClick={(r) => {
      _addResponse(r)
      setSames(false)
    }} />
  }

  return (
    <div className='create-responses'>
      {show}
    </div>
  )

}

export default CreateResponses