import { flatten, map, pipe } from 'ramda'
import { atom, selector } from 'recoil'
import { getIcfs, getSavedResponses } from '../lib/utils'


export const icFileServerUserState = atom({
  key: 'icFileServerUserState',
  default: ''
})

export const perspectivesState = atom({
  key: 'perspectivesState',
  default: []
})


export const responsesState = selector({
  key: 'responsesState',
  get: async ({ get }) => {
    const icFileServerUser = get(icFileServerUserState)
    const savedResponses = await getSavedResponses()
    return savedResponses[icFileServerUser] || []
  }
})

export const icfsState = atom({
  key: 'icfsState',
  default: {
    icfs: getIcfs(),
    disambigs: []
  }
})

export const icfsDisambigsToRemoveState = selector({
  key: 'icfsDisambigsToRemoveState',
  get: ({ get }) => {
    const icfs = get(icfsState)
    if (icfs.disambigs.length === 0) return []
    return pipe(
      map(d => d.options),
      flatten
    )(icfs.disambigs)
  }
})


export const additionalIcState = atom({
  key: 'additionalIcState',
  default: ''
})