import './App.scss'
import {
  Link,
  Outlet
} from 'react-router-dom'
import UserInfo from './UserInfo'
import { useEffect, useState } from 'react'
import { getIcfs, fetchIc, getSavedResponses, setSavedResponses, getStorage, setStorage, createPerspective, getStorageOld, fromsForTo } from './lib/utils'
import { useRecoilState, useRecoilValue } from 'recoil'
import * as st8 from './state'
import { pipe, filter, map, curry, mergeLeft } from 'ramda'

function App() {
  const [icFileServerUser, setIcFileServerUser] = useRecoilState(st8.icFileServerUserState)
  const [responses, setResponses] = useState([])
  const [perspectives, setPerspectives] = useRecoilState(st8.perspectivesState)
  const [icfsConfig, setIcfsConfig] = useRecoilState(st8.icfsState) 

  // App init
  useEffect(() => {
    const go = async () => {
      // perspective stuff
      let persps = (await getStorage('perspectivesState')) || getStorageOld('perspectivesState') || []
      const user = (await getStorage('lastIcFileServerUser')) || getStorageOld('lastIcFileServerUser') || ''
      if(persps.length < 1) {
        console.log('no perspectives found, creating one')
        const persp = createPerspective()
        setPerspectives([persp])
        setIcFileServerUser(persp.address)
      // it does exist
      } else {
        const persp = user  || perspectives[0].address
        setPerspectives(persps)
        setIcFileServerUser(persp)
      }

      // icfs config
      const configIc = await fetchIc('https://' + getIcfs())
      const all = configIc.all({ flatten: true }).filter(t => t.yesNo === '+')
      const disambigs = fromsForTo('disambiguation', all)
        .map(tag => ({
          tag,
          options: fromsForTo(tag, all)
        }))
      setIcfsConfig(mergeLeft({ disambigs }))
    }
    go()
  }, [setPerspectives, setIcFileServerUser])

  // keeping things in sync

  // save responses in local storage
  useEffect(() => {
    if (perspectives.length > 0) {
      setStorage('perspectivesState', perspectives)
    }
  }, [perspectives])
  useEffect(() => {
    if (icFileServerUser) {
      setStorage('lastIcFileServerUser', icFileServerUser)
    }
  }, [icFileServerUser])
  useEffect(() => {
    if (!icFileServerUser) return
    const go = async () => {
      const savedResponses = (await getSavedResponses()) || {}
      savedResponses[icFileServerUser] = responses
      setSavedResponses(savedResponses)
    }
    go()
  }, [responses])

  useEffect(() => {
    const go = async () => {
      const savedResponses = (await getSavedResponses()) || {}
      setResponses(savedResponses[icFileServerUser] || [])
    }
    go()
  }, [icFileServerUser])



  return (
    <div className="App">
        <nav>
          <Link to='/' className='home'><pre>{`
                                ██████                          
                              ██      ████                      
                          ████            ████                  
                      ████                    ████              
                  ████        ░░                  ████          
                ██                                    ████      
      ██████████                  ░░                      ██    
    ██    ▒▒▓▓            ░░                ░░              ██  
  ██                                                      ▒▒██  
  ██                                  ░░              ░░▒▒░░██  
  ██▓▓                                            ░░▒▒▒▒░░░░██  
  ██▒▒▓▓      ░░                                ▓▓▒▒░░▒▒░░▓▓▓▓██
  ██▒▒▓▓▓▓▓▓                ░░      ░░        ▓▓▒▒▒▒░░▓▓▓▓▓▓▒▒██
██▓▓░░▓▓▓▓▓▓▓▓        ░░                  ▓▓▓▓▒▒▒▒▓▓▓▓▓▓▓▓▒▒▓▓██
██░░▒▒▓▓██▓▓▓▓▓▓▓▓                    ▓▓▓▓▒▒▒▒▓▓▓▓░░░░▓▓▒▒░░██  
  ██▒▒░░▓▓▒▒▓▓▓▓▓▓▓▓▓▓                ▓▓▓▓▒▒▓▓░░░░░░░░░░▓▓░░██  
  ██▒▒▒▒░░▓▓▒▒██▓▓▓▓▓▓▓▓▓▓▓▓        ▓▓▓▓▓▓▓▓░░░░░░░░  ▒▒░░██    
  ██▒▒░░▒▒▒▒▒▒▓▓▒▒██▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓░░░░▒▒▒▒▒▒░░████      
    ██▓▓▓▓░░▒▒▒▒░░▓▓▓▓██▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▒▒▒▒▒▒▒▒▒▒▒████          
      ██▓▓██░░░░░░▒▒▒▒▓▓▒▒▓▓▓▓▓▓▓▓▓▓▓▓▒▒░░▒▒▒▒████              
        ████▓▓██░░░░░░▒▒░░▒▒▒▒▓▓▒▒▓▓▒▒░░▓▓▒▒██                  
            ██▓▓▓▓██░░░░▓▓░░▒▒░░▒▒▒▒▓▓▒▒▓▓██                    
              ████▓▓▓▓░░░░▓▓░░▓▓░░░░▒▒▓▓██                      
                  ████▓▓▓▓██████▓▓▓▓▓▓▓▓██                      
                      ██████▓▓▓▓▓▓▓▓████                        
                            ████████                            `}
</pre></Link>
          <UserInfo />
        </nav>
        <div className='main-content'>
          <Outlet context={[responses, setResponses]} />
        </div>
    </div>
  )
}

export default App
