import classNames from "classnames"
import { ethers } from "ethers"
import { append } from "ramda"
import { useState, useRef, useEffect } from "react"
import { useRecoilState, useSetRecoilState } from "recoil"
import { isSomething, setStorage, createPerspective, compressAddr } from "./lib/utils"
import { icFileServerUserState, perspectivesState } from "./state"
import './UserPage.scss'

function UserPage () {
  const [perspectives, setPerspectives] = useRecoilState(perspectivesState)
  const [icFileServerUser, setIcFileServerUser] = useRecoilState(icFileServerUserState)
  const [openI, setOpenI] = useState()
  const inputRef = useRef()
  useEffect(() => {
    if(isSomething(openI)) {
      setTimeout(() => inputRef.current && inputRef.current.focus(), 100)
    }
  }, [openI])
  return (
    <div className='user-page'>
      <h3>Local Perspectives</h3>
      <a className='plus' onClick={() => {
        const persp = createPerspective()
        setPerspectives(append(persp, perspectives))
        setIcFileServerUser(persp.address)
      }}>+</a>
      <ul>
        {perspectives.map((p, i) => {
          const selected = openI === i
          return (
            <li key={p.address} className={classNames('persp', { open: openI === i })}>
              {p.address === icFileServerUser ? ' ⭐️ ' : ''} 
              <a onClick={() => {
                setIcFileServerUser(p.address)
              }}>
                {p.label || compressAddr(p.address)}
              </a>
              <a className='icon' onClick={() => setOpenI(selected ? null : i)}>⚙️</a>
              {p.label ? <span className='hint'> {selected ? p.address : compressAddr(p.address)}</span> : ''}
              <div className='label-wrap'>
                <input 
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      setOpenI(null)
                    }
                  }}
                  ref={selected ? inputRef : null}   
                  type='text' 
                  placeholder='give your IC a name'
                  value={p.label || ''} 
                  onChange={evt => {
                    const ps = perspectives.map(p2 => {
                      if (p2.address === p.address) {
                        p2 = Object.assign({}, p2, { label: evt.target.value })
                      }
                      return p2
                    })
                    setPerspectives(ps)
                  }}
                />
              </div>
            </li>
          )
        })}
      </ul>
      {window.ethereum && <div className='metamask'>
        <a className='' onClick={async () => {
          const provider = new ethers.providers.Web3Provider(window.ethereum)
          await provider.send("eth_requestAccounts", []);
          const signer = provider.getSigner()
          const addr = await signer.getAddress()
          setIcFileServerUser(addr)
        }}><h3>Use Metamask</h3></a>
      </div>}
      <div className='hint'>
        <p>
          This is like your account page. When you first got to the site we created an identity  for you (we call them "perspectives") and stored it in your browser. That funny looking "{compressAddr(icFileServerUser)}" thing is all we ever know about you and all we store on our servers. 
        </p>
        <p>
          A couple tips...<br/> + Click the gear icon to give your perspective a name that makes sense to you. 
          <br/> + Create as many perspectives as you want and play each day's game with different perspectives. Play as your ideal self, or your mom, or someone you see on the street.
        </p>
        <p>
          Finally, if you're one of the cool kids you can use a MetaMask identities to play the game.
        </p>
      </div>
    </div>
  )
}

export default UserPage