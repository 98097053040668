import { useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil"
import { createPerspective, getStorage, labelForUser, setStorage } from "./lib/utils"
import { perspectivesState, icFileServerUserState } from "./state"
import './UserInfo.scss'
import { ethers } from "ethers"
import { append } from "ramda"

function UserInfo ({ }) {
  const [icFileServerUser, setIcFileServerUser] = useRecoilState(icFileServerUserState)
  const [perspectives, setPerspectives] = useRecoilState(perspectivesState)
  const { pathname } = useLocation()
  const username = <span><Link to={pathname === '/account' ? '/' : '/account'}>{labelForUser(icFileServerUser, perspectives)}</Link></span>

  // keep storage insync

  if (!icFileServerUser) return null
  return <span className='user-info'>
    {username}
  </span> 
}
export default UserInfo
