import { append } from 'ramda'
import { useSetRecoilState } from 'recoil'
import * as st8 from './state'
import { useEffect, useCallback } from 'react'

export default function Samesies ({ original, suggested, onClick }) {
  const setAdditionalIc = useSetRecoilState(st8.additionalIcState)
  const yes = () => {
    setAdditionalIc(append(`\n${original}\n+${suggested}`))
    onClick && onClick(suggested)
  }
  const no = () => {
    setAdditionalIc(append(`\n${original}\n-${suggested}`))
    onClick && onClick(original)
  }
  const handleKeyPress = useCallback((event) => {
    if (event.key === 'y') yes()
    if (event.key === 'n') no()
  }, [])

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress)

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    };
  }, [handleKeyPress])
  return (
    <div className='samesies'>
      <h2>
        Would you say "{original}" is the same as "{suggested}"?
      </h2>
      <div className='controls'>
        <button tabIndex={1} onClick={yes}><span className='emoji'>👍</span> <span className='explain'>yes</span></button>
        <button tabIndex={2} onClick={no}><span className='emoji'>👎</span> <span className='explain'>no</span></button>
      </div>
      <div className='desktop-only hint'>
        "y" for yes&nbsp;&nbsp;"n" for no
      </div>
    </div>
  )
}