import { filter, map, curry, pipe, uniq, count } from "ramda"
import { useEffect, useRef, useState } from "react"
import IC from "ic-js"
import { getConfig, getIcfs, fromsForTo, cleanAndCountTags } from "./lib/utils"
import classNames from "classnames"
import { useOutletContext } from 'react-router-dom'
import { useRecoilValue } from "recoil"
import * as st8 from './state'

const skippedTo = `"${getConfig('prompt')}" skipped`
const prompt = getConfig('prompt')


export default function RountTwo ({ onPatch, onGame }) {
  const ic = useRef()
  const myIc = useRef()
  const [data, setData] = useState([])
  const [myData, setMyData] = useState([])
  const [options, setOptions] = useState([])
  const [optionsSeen, setOptionsSeen] = useState([])
  const [skips, setSkips] = useState([])
  const [lowData, setLowData] = useState([])
  const [controversials, setControversials] = useState([])
  const [lastSaved, setLastSaved] = useState(Date.now())
  const [responses, setResponses] = useOutletContext()
  const [randomI, setRandomI] = useState(null)
  const disambigs = useRecoilValue(st8.icfsDisambigsToRemoveState)


  // init
  useEffect(() => {
    ic.current = new IC()
    ic.current.import(`https://${getIcfs()}/index.ic`)
    ic.current.on('data', setData)

    myIc.current = new IC()
    myIc.current.on('data', setMyData)
    onGame('Go together?')
  }, [setData, setMyData])

  useEffect(() => {
    const skippedCounts = cleanAndCountTags(data.filter(d => d.to === skippedTo))
    const counts = cleanAndCountTags(data.filter(t => t.to === prompt))
    const totalPerspectives = uniq(data.map(d => d.dId)).length
    const scs = skippedCounts.filter(s => s.yes > totalPerspectives * 0.15).map(s => s.tag)
    setSkips(scs)
    setControversials(counts.filter(s => !scs.includes(s.tag)).filter(s => s.count > totalPerspectives * 0.15 && s.sum < totalPerspectives * 0.15).map(c => c.tag))
    setLowData(counts.filter(s => !scs.includes(s.tag)).filter(s => s.count < totalPerspectives * 0.25).map(c => c.tag))
  }, [data])

  // update options
  useEffect(() => {
    console.log(lowData)
    const myResponses = fromsForTo(prompt, myData)
    const mySkipped = fromsForTo(skippedTo, myData)
    const opts = pipe(
      fromsForTo(prompt),
      filter(d => !responses.includes(d) 
        && !skips.includes(d)
        && !myResponses.includes(d) 
        && !mySkipped.includes(d)
        && !disambigs.includes(d)
      )
    )(data)
    let theOpts = opts
    // controversials
    const conts = opts.filter(o => controversials.includes(o))
    const lows = opts.filter(o => lowData.includes(o))
    if (conts.length) {
      theOpts = conts
      onGame && onGame('Controversial')
    } else if (lows.length) {
      theOpts = lows
      onGame && onGame('Low Data')
    } else {
      onGame && onGame('Go together?')
    }
    setOptions(theOpts)
    setRandomI(Math.floor(Math.random() * theOpts.length))
  }, [data, setOptions, responses, setRandomI, myData, skips])

  const currentOption = options[randomI]

  const btnClicked = (to, from, yesNo) => {
    myIc.current.tag(to, from, yesNo)
    setResponses(uniq([...responses, from]))
    const icStr = myIc.current.export(filter(t => t.time > lastSaved)).replace(/^_\n/,  '')
    setLastSaved(Date.now())
    onPatch && onPatch(icStr)
  }
  let show = <>
    <h2>{currentOption}</h2>
    <div className="controls">
      <button onClick={() => {
        btnClicked(prompt, currentOption)
      }}><span className='emoji'>👍</span> agree</button>
      <button onClick={() => {
        btnClicked(prompt, currentOption, '-')
      }}><span className='emoji'>🙅</span> disagree</button>
      <div className='skip'>
        <button onClick={() => {
          btnClicked(skippedTo, currentOption)
        }}>🤷 skip</button>
      </div>
    </div>
    <div className='responses-left hint'>
      {responses.length} done / {options.length} left
    </div>
  </>
  if(responses.length > 0 && options.length === 0) {
    show = <>
      <h2>ya done did em all up! 🏆</h2>
      <p>Thanks for your help. When I get enough people I'll crunch the numbers and let you know your tribe.</p>
    </>
  }

  return (
    <div className='round-two'>
      <div className='option-wrap'>
        {show}
      </div>
    </div>
  )
}