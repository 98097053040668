import { useRecoilValue } from 'recoil'
import { Link } from 'react-router-dom'
import { icFileServerUserState } from './state'
import { compressAddr, getConfig } from './lib/utils'
import { useState } from 'react'
import classNames from 'classnames'
import "./Rules.scss"

function Rules ({ }) {
  const icFileServerUser = useRecoilValue(icFileServerUserState)
  const [open, setOpen] = useState(false)
  const maxChars = getConfig('maxChars')
  const minResponses = getConfig('minResponses')
  const maxResponses = getConfig('maxResponses')
  return (
    <div className={classNames('rules', { open })}>
      <a className='open' onClick={() => setOpen(!open)}>What's going on here? <span className='caret' /></a>
      <div className='rules-wrap'>
        <p>
          PBNJ is the ultimate <em>mind game</em>. We're creating meaning together.
        </p>
        <p>&nbsp;</p>
        {/* <p>
          THE BASICS:
        </p>
        <ul>
          <li>+ every day is a new prompt</li>
          <li>+ add at least {minResponses} responses to the prompt to play</li>
          <li>+ get points when people agree (or disagree!)</li>
          <li>+ the computers will help you find <em>your people</em></li>
          <li>+ play as many times as you like by creating more <Link to='/account'>perspectives</Link></li>
        </ul>
        <p>
          ROUND 1:
        </p>
        <ul>
          <li>+ {maxResponses} responses max</li>
          <li>+ {maxChars} characters max</li>
          <li>+ no punctuation except ' and "</li>
          <li>+ minimum {minResponses} responses to move to Round 2</li>
        </ul>
        <p>
          ROUND 2:
        </p>
        <ul>
          <li>+ see everyone else's responses</li>
          <li>+ "agree", "disagree", or "skip"</li>
        </ul>
        <p>
          WINNING:&nbsp; <span className='hint'>"I didn't come here to make friends"</span>
        </p>
        <ul>
          <li>+ when someone agrees (or disagrees!) with a response a point is generated and split between everyone who created that response</li>
          <li>+ that means you'll score highest if a lot of people interact with your response in Round 2, but very few people created that response in Round 1</li>
        </ul>
        <p>
          FINDING YOUR PEOPLE: &nbsp; <span className='hint'>"I came here to make friends"</span>
        </p>
        <ul>
          <li>+ we'll use machine learning to find clusters of perspectives</li>
          <li>+ each day every perspective will be assigned a tribe</li>
          <li>+ if you use the same perspective over multiple day's prompts we can cluster those</li>
          <li>+ hint: use different perspectives to see what tribe they're in</li>
        </ul> */}
        <p>PRIVACY:</p>
        <ul>
          <li>+ all <span className='pbnj'>pbnj</span> data is <b>open</b> and <b>public</b></li>
          <li>+ your responses are <b>anonymous</b>. they are tied together with your perspective (ex: {compressAddr(icFileServerUser)})</li>
          <li>+ other than that there is no tracking whatsoever</li>
          <li>+ when you delete the perspective from your browser its connection to "you" is gone forever</li>
        </ul>
        <p>
          73CH D3375:
        </p>
        <ul>
          <li>+ pbnj is built using <a target='_blank' href="https://github.com/owise1/ic-js">intelligence collective</a> and <a target='_blank' href="https://github.com/owise1/ic-file-server">icfs</a></li>
        </ul>
      </div>
    </div>
  )
}

export default Rules