import { useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import CreateResponses from "./CreateResponses"
import * as st8 from './state'
import RoundTwo from './RoundTwo'
import './Welcome.scss'
import Rules from './Rules'
import classNames from 'classnames'
import { useOutletContext } from 'react-router-dom'
import IC from 'ic-js'
import { getConfig, saveIc, getIcfs, getPrompt, patchIc } from './lib/utils'
import { ethers } from 'ethers'

function Welcome() {
  const [responses, setResponses] = useOutletContext()
  const [perspectives, setPerspectives] = useRecoilState(st8.perspectivesState)
  const icFileServerUser = useRecoilValue(st8.icFileServerUserState)
  const [additionalIc, setAdditionalIc] = useRecoilState(st8.additionalIcState)
  const perspective = perspectives.find(p => p.address === icFileServerUser)
  const [game, setGame] = useState('')
  if (!perspective) return null
  const round = perspective.saved ? 2 : 1 

  const save = async (str) => {
    const server = 'https://' + getIcfs() 
    const persp = perspectives.find(p => p.address === icFileServerUser)
    let signer 
    if (persp) {
      signer = new ethers.Wallet(persp.private)
    } else if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      await provider.send("eth_requestAccounts", []);
      signer = provider.getSigner()
      const addr = await signer.getAddress()
      if (addr !== icFileServerUser) {
        signer = null
        alert(`${addr} is not the address of the perspective you selected.`)
      }
    }
    if (signer) {
      // first time
      if(!str) {
        const ic = new IC({ pure: false })
        responses.forEach(response => {
          ic.tag(getPrompt(), response)
          ic.tag(`pbnj.app round 1 "${getPrompt()}"`, response)
        })
        str = ic.export()
        await saveIc(signer, server, icFileServerUser, str + additionalIc)
        setPerspectives(ps => ps.map(p => {
          if (p.address === perspective.address) {
            return { ...p, saved: Date.now() }
          }
          return p
        }))
      } else {
        await patchIc(signer, server, icFileServerUser, str + additionalIc)
      }
      setAdditionalIc('')
    }
  }


  return (
    <div className={classNames('welcome', `round-${round}`)}>
      <div className='game-wrap'>
        <h1>{window.__pbnj.prompt}</h1>
        {round === 1 &&
          <CreateResponses onSave={() => save()} />
        }
        {round === 2 && <RoundTwo onPatch={save} onGame={setGame} />}
      </div>
      <footer className='main-footer'>
        <h3>{round === 1 ? 'Round 1' : game}</h3>
        <Rules />
      </footer>
    </div>
  )
}
export default Welcome